import * as actionTypes from './actionTypes';


export const setPlayingVideo = (isVideoPlaying, playingVideoWistiaHashedId, playingVideoContentId) => {
    return {
        type:actionTypes.SET_PLAYING_VIDEO,
        isVideoPlaying: isVideoPlaying,
        playingVideoWistiaHashedId: playingVideoWistiaHashedId,
        playingVideoContentId: playingVideoContentId
    }
};
