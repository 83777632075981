import React, {Component} from 'react';
import Modal from "react-bootstrap/esm/Modal";
import SecondaryButton from "../../../../components/UI/Buttons/SecondaryButton";

import classes from './FacialTrainingAlert.module.scss';



/**
 * CHECKED-2024
 *
 * Use zindex property to allow modal in modal.
 */
class FacialTrainingAlert extends Component {

    closeModal = () => {
        this.props.onClose();
    };

    gotoAmiOnline = ()=>{
        window.open("https://us.allerganmedicalinstitute.com/us/en/splash-page.html","_blank");

        this.closeModal();
    }

    render() {

        const customStyle = {};
        if(this.props.zindex){
            customStyle.zIndex = this.props.zindex;
        }


        return (
            <Modal className={"shadow "+classes.modal}
                   centered
                   style={customStyle}
                   show={this.props.show}
                   onHide={this.closeModal}>

                <Modal.Body className={classes.body}>
                    <img className={classes.closeBtn}
                         onClick={this.closeModal}
                         src={require("../../../../assets/images/icons/ico-close.svg").default} alt=""/>

                    <div className={"mt-4 text-center mb-4"}>
                        <div>
                            Please note: An Allergan Account Number is required to access this site.
                        </div>
                        <div className={classes.buttonRow}>
                            <SecondaryButton className={classes.button +" align-center"} iconType={"none"} onClick={this.closeModal}>Go Back</SecondaryButton>
                            <div className={classes.buttonSpacer}>&nbsp;</div>
                            <SecondaryButton className={classes.button} iconType={"none"} onClick={this.gotoAmiOnline}>Proceed to AMIOnline.com</SecondaryButton>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}


export default FacialTrainingAlert;

