import React, {Component} from 'react';
import {connect} from 'react-redux';

import firebase from 'firebase/app';
import 'firebase/storage';


/**
 * CHECKED-2021
 *
 * Displays the users profile image.
 */
class ProfileImage extends Component {


    state = {
        profile_img_url:null
    };



    componentDidMount(){

        this.downloadImage();
    }


    componentDidUpdate(prevProps, prevState){

        // console.log("this.props.profileImgUpdated", this.props.profileImgUpdated)

        if(prevProps.profileImgUpdated!==this.props.profileImgUpdated
            || prevProps.user_id!==this.props.user_id){

            this.downloadImage();
        }
    }


    downloadImage=()=>{

        // console.log("this.props.user_id",this.props.user_id);

        const storage = firebase.storage();

        // Create a storage reference from our storage service
        const storageRef = storage.ref();

        const path = "users/" + this.props.user_id + "/images/profile";

        // console.log(path);

        // Child references can also take paths delimited by '/'
        const spaceRef = storageRef.child(path);

        spaceRef.getDownloadURL()
            .then((url) => {

                // console.log("HEADER PROFILE", url);

                this.setState({profile_img_url:url});
            })
            .catch((error) => {

                //
                // If the profile image does not exist use the local default version.
                //
                this.setState({profile_img_url:require("../../assets/images/icons/ico-user-default.svg").default});
            });
    };



    render() {

        let width = this.props.width ? this.props.width : 50;
        let height = this.props.height ? this.props.height : 50;

        let img = null;
        if(this.state.profile_img_url){
            img = <img src={this.state.profile_img_url} width={width} height={height} style={{borderRadius:"50%"}} className={this.props.className?this.props.className:""} alt=""/>
        }

        return (
            <React.Fragment>
                {img}
            </React.Fragment>
        )
    }
}


/**
 * This is need when somebody changes their profile image.
 * If profileImgUpdated is incremented, the profile should be updated.
 */
const mapStateToProps = (state) => {
    return {
        profileImgUpdated: state.misc.profileImgUpdated
    }
};



export default connect(mapStateToProps)(ProfileImage);


