import React, {Component} from 'react';
import {connect} from 'react-redux';

import firebase from 'firebase/app';
import "firebase/analytics";

import * as actions from "../../store/actions";
import * as errorHelper from "../../utils/error-helper";
import axios from "../../utils/config-axios";

// import {addRecentlyViewed} from '../../pages/Recent/recently-viewed-utils';
// import CustomFullScreenButton from './CustomFullScreenButton';

import classes from './WistiaManager.module.scss';

//
// DO NOT CHANGE THIS!!!!
// Once set it should stay this value for ever!!!!
//
const SLOT_TIME_INTERVAL = 20;


class WistiaManager extends Component{


    constructor(props) {
        super(props);

        this.initWistiaGlobalUtils();
    }


    initWistiaGlobalUtils = () => {

        if (!document.getElementById("wistia_script") ) {

            //
            // Load E-v1.js
            //
            const wistiaScript = document.createElement("script");
            wistiaScript.id = "wistia_script";
            wistiaScript.type = "text/javascript";
            wistiaScript.src = "https://fast.wistia.com/assets/external/E-v1.js";
            wistiaScript.async = true;
            document.body.appendChild(wistiaScript);
        }


        window._wq = window._wq || [];


        if(window._d2WistiaInitProcessed){
            return;
        }

        window._d2WistiaInitProcessed=true;


        //
        // Load custom buttons or plugins.
        //
        // window.wistiaInitQueue = window.wistiaInitQueue || [];
        // window.wistiaInitQueue.push( (W) => {
        //     window.Wistia.defineControl(CustomFullScreenButton);
        // });


        //
        // Only run this once.
        //
        window._wq.push({

            id: "_all",

            options: {
                // autoPlay: true,
                controlsVisibleOnLoad: true,
                // email: "lennythedog@wistia.com",
                endVideoBehavior: "reset",
                fullscreenButton: true,
                resumable: true,
                videoFoam: true,
                playbackRateControl:false,
                qualityControl:false,
                // customFullScreenButton: true,
                // fakeFullscreen: true,
                // googleAnalytics: true,
                // playButton: false,
                // playbar: false,
                //playerColor: "#FF00FF",
                playerColor: "#071d49",
                // seo: true,
                // stillUrl: "https://my-awesome-website.com/my-thumbnail-url.jpg",
                //stillUrl:"https://firebasestorage.googleapis.com/v0/b/allergan-ami-d2-dev-6a857.appspot.com/o/content%2Fdocuments%2F42%2F1024haywardLogo.png?alt=media",
                // volume: 0.5,
                wmode: "transparent"
            },

            onReady: (video) => {

                // console.log("global-onready", video.hashedId());
                // console.log("username",this.props.username);

                //
                // Set email/username for Wistia tracking.
                //
                if(this.props.username){
                    video.email(this.props.username);
                }



                video.bind("play", () => {

                    try {

                        // console.log("WistiaManager - Playing:", video.hashedId(), video.duration());

                        const wrapperDiv = document.getElementById("wistia_wrapper_"+video.hashedId());

                        if( !wrapperDiv ){
                            throw new Error("Wistia wrapper div not found. Using hashedId:"+video.hashedId());
                        }

                        const content_id = wrapperDiv.getAttribute("data-content_id");

                        // console.log("content_id",content_id);

                        video.__ami_content_id = parseInt(content_id);

                        video.__ami_trackVideo = this.trackVideo;

                        const numSlot = Math.floor(video.duration() / SLOT_TIME_INTERVAL);

                        video.__ami_trackingSlots = [];
                        video.__ami_trackingSlots[numSlot]=0; // Set the last slot to zero.
                        video.__ami_trackingSlotsCount = [];


                        this.trackVideo(video,"p",[]);


                        //
                        // Stop any other videos playing...
                        //
                        const allVideos = window.Wistia.api.all();
                        for (let i = 0; i < allVideos.length; i++) {

                            // console.log(allVideos[i].hashedId())

                            if (allVideos[i].hashedId() !== video.hashedId()) {

                                try {
                                    allVideos[i].pause();
                                } catch(error){
                                    console.log("Failed to pause running video:", allVideos[i].hashedId())
                                }
                            }
                        }



                        this.props.onSetPlayingVideo(true, video.hashedId(), content_id);

                        //
                        // Firebase tracking
                        //
                        firebase.analytics().logEvent('view_content', {
                            content_type: 'video',
                            content_id: content_id,
                            wistia_code: video.hashedId()
                        });

                    } catch (error) {
                        console.log("WistiaManager - Error detected during 'play' event.", error);
                    }
                });


                video.bind("pause", () => {

                    try {
                        // console.log("The video was just paused!");

                        //
                        // On pause, set the slot to viewed.
                        //
                        const slot = Math.floor(video.time() / SLOT_TIME_INTERVAL);

                        if (!video.__ami_trackingSlots) {
                            console.log("WistiaManager - Error detected during 'pause' event. __ami_trackingSlots is not defined.");
                            return;
                            // throw new Error("__ami_trackingSlots is not defined.");
                        }

                        video.__ami_trackingSlots[slot] = 1;
                        video.__ami_trackVideo(video, "s", video.__ami_trackingSlots);

                        this.props.onSetPlayingVideo(false, null, null);

                    } catch(error){
                        console.log("WistiaManager - Error detected during 'pause' event.", error);
                    }
                });

                video.bind("end", () => {

                    try {
                        // console.log("The video ended!", video.time(), video.duration(), video.length);

                        //
                        // On end, set the slot to viewed.
                        //
                        const numSlot = Math.floor(video.duration() / SLOT_TIME_INTERVAL);

                        if (!video.__ami_trackingSlots) {

                            console.log("WistiaManager - Error detected during 'end' event. __ami_trackingSlots is not defined.");
                            return;
                            // throw new Error("__ami_trackingSlots is not defined.");
                        }


                        video.__ami_trackingSlots[numSlot] = 1;
                        video.__ami_trackVideo(video, "e", video.__ami_trackingSlots);

                        this.props.onSetPlayingVideo(false, null, null);


                    } catch(error){
                        console.log("WistiaManager - Error detected during 'end' event.", error);
                    }
                });

                video.bind("secondchange", function(s) {

                    // For now, log statement.
                    console.log("WistiaManager - secondchange event:", video.hashedId(), s, !!video.__ami_trackingSlotsCount, !!video.__ami_trackVideo);

                    try {
                        // console.log("Second change",s);
                        // console.log("", video.time(), video.duration());

                        const slot = Math.floor(video.time() / SLOT_TIME_INTERVAL);

                        // console.log("slot", slot);
                        // console.log("video.hashedId()", video.hashedId());
                        //
                        // WARNING: Its possible "secondchange" event can be called before
                        // the "play" event is called.
                        // The "play" event would initialize the __ami_trackingSlotsCount.
                        // DO NOT through an ERROR. This will block the "secondchange" event
                        // handler from being called.
                        //
                        if(!video.__ami_trackingSlotsCount){
                            console.log("WistiaManager - Error detected during 'secondchange' event. __ami_trackingSlotsCount is not defined. hashedId=", video.hashedId());
                            return;
                            // throw new Error("__ami_trackingSlotsCount is not defined.")
                        }
                        if(!video.__ami_trackVideo){
                            console.log("WistiaManager - Error detected during 'secondchange' event. __ami_trackVideo is not defined. hashedId=", video.hashedId());
                            return;
                            // throw new Error("__ami_trackVideo is not defined.")
                        }


                        //
                        // Keep count of the number of times the slot has been called.
                        // If 3 or more, set the slot as viewed.
                        // This is required to prevent the scroll being moved back and forth
                        // on the timeline.
                        //
                        if(video.__ami_trackingSlotsCount[slot]===undefined || video.__ami_trackingSlotsCount[slot]===null){
                            video.__ami_trackingSlotsCount[slot] = 1;
                        } else {
                            video.__ami_trackingSlotsCount[slot] = video.__ami_trackingSlotsCount[slot]+1;

                            if(video.__ami_trackingSlotsCount[slot]>3){
                                if(video.__ami_trackingSlots[slot]===undefined || video.__ami_trackingSlots[slot]===null){
                                    video.__ami_trackingSlots[slot] = 1;

                                    video.__ami_trackVideo(video, "t", video.__ami_trackingSlots);
                                }
                            }
                        }

                        // console.log(video.__ami_trackingSlots);

                        // if (s % 15 === 0 ) {
                        //
                        //     const watchedVector = video.secondsWatchedVector();
                        //     video.__ami_trackVideo(video.hashedId(),"t",watchedVector);
                        // }

                    } catch(error){

                        console.log("WistiaManager - Error detected during 'secondchange' event: hashedId=", video.hashedId());
                        console.log("WistiaManager - Error detected during 'secondchange' event.", error);
                    }
                });
            }
        });
    };



    trackVideo = (video, action, vector) => {

        if(!vector){
            vector=[];
        }

        const numSlot = Math.floor(video.duration() / SLOT_TIME_INTERVAL);

        const data = {
            wistia_hashed_id:video.hashedId(),
            user_id:this.props.security.user_id,
            action:action,
            vector:vector.toString(),
            payload:{
                content_id: (video.__ami_content_id)?video.__ami_content_id:null,
                duration:video.duration(),
                num_slots: numSlot,
                interval: SLOT_TIME_INTERVAL
            }
        };


        // console.log(data);
        console.log("WistiaManager - Sending video-tracker data...", video.hashedId());
        // console.log("WistiaManager - Sending video-tracker data:", video.hashedId(), data.action, data.payload);


        axios({
            url: '/api/private/content/video/video-tracker',
            method: 'post',
            timeout: 1000 * 10, // Wait for 5 seconds
            data:data
        }).catch(error=>{
            console.log("Failed to send video tracking stats.", errorHelper.buildLogMessage(error));
        });
    };


    render() {

        if( this.props.supportBackdrop ){
            const classArray = [classes.Backdrop];
            return (
                this.props.isVideoPlaying ? <div className={classArray} onClick={this.props.clicked}></div> : null
            );
        }

        // No backdrop
        return null;
    }
}


const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        username: state.security.username,
        isVideoPlaying: state.video.isVideoPlaying,
        playingVideoWistiaHashedId: state.video.playingVideoWistiaHashedId,
        playingVideoContentId: state.video.playingVideoContentId,
        security:state.security
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetPlayingVideo: (isVideoPlaying, playingVideoWistiaHashedId, playingVideoContentId) => {
            dispatch( actions.setPlayingVideo(isVideoPlaying, playingVideoWistiaHashedId, playingVideoContentId) );
        }
    }

};


export default connect(mapStateToProps, mapDispatchToProps)(WistiaManager);

