import React from 'react';

import classes from './TopMenuChevron.module.scss';

const TopMenuCheveron = (props) => {

    const clazz = [classes.cheveron];

    if(props.arrowUp){
        clazz.push(classes.flipVertical)
    }

    return (
        <img className={clazz.join(' ')} src={require("../../assets/images/icons/ico-chevron-reg-down.svg").default} alt=""/>
    );
};

export default TopMenuCheveron;