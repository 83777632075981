import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import RetinaImage from '../RetinaImage/RetinaImage';
// import DatedBanner from "../Banners/DatedBanner/DatedBanner";


import classes from './PublicHeader.module.scss';

class PublicHeader extends Component {


    goHome = () => {
        this.props.history.push("/");
    };

    render() {

        return (
            <React.Fragment>

                {/*<DatedBanner code={"privacy-policy-update-dec-2022"} startDate={"2022/12/16"} endDate={"2022/12/31"}/>*/}

                <header className={classes.header}>
                    <Container>
                        <Row>
                            <Col sm={6}>
                                        <RetinaImage className={classes.logoImg+" img-fluid"}
                                                    onClick={this.goHome}
                                                    width={393}
                                                    height={38}
                                                    src={[
                                                        require("../../assets/images/logos/logo-allergan-ko.png").default,
                                                        require("../../assets/images/logos/logo-allergan-ko@2x.png").default,
                                                        require("../../assets/images/logos/logo-allergan-ko@3x.png").default]}
                                             alt="Allergan Medical Institue" />
                            </Col>
                            <Col sm={6}>
                                                <RetinaImage className={classes.logoImg+" img-fluid"}
                                                onClick={this.goHome}
                                                width={560}
                                                height={31}
                                                src={[
                                                    require("../../assets/images/logos/logo-pillar.png").default,
                                                    require("../../assets/images/logos/logo-pillar@2x.png").default,
                                                    require("../../assets/images/logos/logo-pillar@3x.png").default]}
                                                alt="Allergan Medical Institue" />
                            </Col>
                        </Row>
                    </Container>

                </header>
            </React.Fragment>
        );
    }
}

export default withRouter(PublicHeader);