import React from 'react';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router-dom';


/**
 * CHECKED-2021
 *
 * Redirects user to the correct home page.
 * If the user is logged in, they will be sent to: /home
 * If not logged in: /
 */
const PublicHomeRouteManager = ({ component: Component, user_id, ...rest }) => {

    return   (
        <Route {...rest} render={(props) => (
            !user_id ?
                <Component {...props} /> : <Redirect to={{pathname: '/home', state: {from: props.location}}}/>
        )}/>
    );
};



export default PublicHomeRouteManager;

