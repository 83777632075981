import React from 'react';

import classes from './ActionButton.module.scss';


const ActionButton = (props) => {

    const lc = [];
    if(props.underline){
        lc.push(classes.underline)
    }

    if(props.alignStart){
        lc.push(classes.alignStart)
    }

    if(props.bold){
        lc.push(classes.bold)
    }

    if(props.decorationHover) {
        lc.push(classes.decorationHover);
    }

    return (
        <button className={classes.actionButton+" "+lc.join(' ')+" "+props.className}
                onClick={props.onClick}
                style={props.style}>{props.children}</button>
    );
};


export default ActionButton;
