import React, {Component} from 'react';
import classes from './BackToTop.module.scss';



class BackToTop extends Component {

    state = {
        is_visible: false
    };


    componentDidMount() {
        document.addEventListener("scroll", this.toggleVisibility);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.toggleVisibility);
    }


    toggleVisibility = () => {

        this.setState({
            is_visible: (window.pageYOffset > 300)
        });
    };


    scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };



    render() {

        const { is_visible } = this.state;

        if(!is_visible){
            return null;
        }

        return (
            <div className={classes.BackToTop}>
                <div className={classes.linkTop} onClick={this.scrollToTop}>
                    Back To Top<i className="fas fa-arrow-up pl-2"></i>

                    {/* <img align="right" height="40" width="40" src={require("../../assets/images/icons/ico-chevron-reg-up.svg").default} alt='Back to top'/>  */}
                </div>
            </div>

        );
    }
}

export default BackToTop;