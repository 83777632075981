import React from 'react';

import {withRouter} from 'react-router-dom';


/**
 * CHECKED-2021-P2
 *
 * Use in the ProtectedRoutre class place around:
 *
 *  "<SessionTimeout><Component {...props} /></SessionTimeout>"
 */

class SessionTimeout extends React.Component {

    //
    // Set timeouts here...
    // Use milliseconds.
    //
    // this.state = {
    //     warningTime: 1000 * 60 * 10,
    //     signoutTime: 1000 * 60 * 15,
    // };
    state = {
        warningTime: 1000 * 3,           // Not used
        signoutTime: 1000 * 60 * 60 * 2  // 2 hrs
    };




    componentDidMount() {

        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        for (let i in this.events) {
            window.addEventListener(this.events[i], this.resetTimeout);
        }

        this.setTimeout();
    }


    componentWillUnmount() {

        this.events = [
            'load',
            'mousemove',
            'mousedown',
            'click',
            'scroll',
            'keypress'
        ];

        for (let i in this.events) {
            window.removeEventListener(this.events[i], this.resetTimeout);
        }

        this.clearTimeoutFunc();
    }



    clearTimeoutFunc = () => {
        //if (this.warnTimeout) clearTimeout(this.warnTimeout);
        if (this.logoutTimeout) clearTimeout(this.logoutTimeout);

        //this.warnTimeout = null;
        this.logoutTimeout = null;
    };


    setTimeout = () => {
        //this.warnTimeout = setTimeout(this.warn, this.state.warningTime);
        this.logoutTimeout = setTimeout(this.logout, this.state.signoutTime);
    };

    resetTimeout = () => {
        this.clearTimeoutFunc();
        this.setTimeout();
    };

    // warn = () => {
    //     // window.alert("You will be logged out automatically in 1 minute")
    //     // console.log('You will be logged out automatically in 1 minute.');
    //     //
    //     // this.setState({
    //     //     showTimeoutModal:true
    //     // });
    // };

    logout = () => {
        //
        // Log the user out.
        //
        this.props.history.push("/logout?session-expired=y");
    };


    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}


export default withRouter(SessionTimeout);