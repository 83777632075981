import React from 'react';

import classes from './SideMenuChevron.module.scss';

const SideMenuCheveron = (props) => {

    const clazz = [classes.cheveron];

    if(props.state==="open"){
        clazz.push(classes.flipVertical)
    }



    return (
        <img className={clazz.join(' ')} src={require("../../assets/images/icons/ico-chevron-reg-down.svg").default} {...props} alt=""/>
    );
};

export default SideMenuCheveron;