import React from 'react';

import Button from 'react-bootstrap/Button';


const SecondaryButton = (props) => {

    const styles = {
        textAlign:"center"
    };

    if(props.width){
        styles.width = props.width;
    }

    if(props.height){
        styles.height = props.height;
    }

    return (
        <Button className={"btn btn-secondary "+props.className}
                onClick={props.onClick}
                style={styles}
        >{props.children}</Button>
    );
};


export default SecondaryButton;
