import React from 'react';
import {Route} from 'react-router-dom';
import {Redirect} from 'react-router-dom';

import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import SessionTimeout from '../../components/SessionTimeout/SessionTimeout';



/**
 * CHECKED-2021
 *
 * Checks users role to determine if they have access to the component.
 */
const ProtectedRoute = ({ component: Component, user_id, role_id, allowed_roles,  ...rest }) => {

    // console.log("PROTECTED-ROUTE CALLED", user_id, role_id, allowed_roles);

    let allowAccess=false;

    if(user_id && !allowed_roles){
        allowAccess=true;
    } else if( allowed_roles && allowed_roles.includes(role_id) ){
        allowAccess=true;
    }



    return   (
        <Route {...rest} render={(props) => (
            allowAccess ?
                <SessionTimeout><Component {...props} /></SessionTimeout> : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
        )}/>
    );

    // return   (
    //     <Route {...rest} render={(props) => (
    //         allowAccess ?
    //             <Component {...props} /> : <Redirect to={{pathname: '/', state: {from: props.location}}}/>
    //     )}/>
    // );
};





const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id
        // username: state.security.username,
        // firstname: state.security.firstname,
        // lastname: state.security.lastname
    }
};


export default connect(mapStateToProps, null)(withRouter(ProtectedRoute));


