import React, { Component } from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Suspense} from 'react';
import {connect} from 'react-redux';

import RouteWrapper from './hoc/RouteWrapper/RouteWrapper';
import PublicHomeRouteManager from './hoc/RouteWrapper/PublicHomeRouteManager';
import PublicHeader from './components/Header/PublicHeader';
import Footer from './components/Footer/Footer';
import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';
import Spinner from './components/UI/Spinner/Spinner';


// Lazy Load...
const Logout = React.lazy(() => import('./pages/public/Logout/Logout'));
const PublicHome = React.lazy(() => import('./pages/public/PublicHome/PublicHome'));
const Registration = React.lazy(() => import('./pages/public/Registration/Registration'));
const RegistrationCompleted = React.lazy(() => import('./pages/public/Registration/RegistrationCompleted'));
const MobileTermsAndConditions = React.lazy(() => import('./pages/public/Registration/MobileTermsAndConditions'));
const RequestPasswordChange = React.lazy(() => import('./pages/public/RequestPasswordChange/RequestPasswordChange'));
const ChangePassword = React.lazy(() => import('./pages/public/ChangePassword/ChangePassword'));
const TechnicalSupport = React.lazy(() => import('./pages/public/TechnicalSupport/TechnicalSupport'));
const OptOut = React.lazy(() => import('./pages/public/OptOut/OptOut'));
const SaveForLaterPublic = React.lazy(() => import('./pages/public/SaveForLater/SaveForLaterPublic'));
const OauthCallback = React.lazy(() => import('./pages/public/oauth/OauthCallback'));


class PublicRoutes extends Component {


    render() {

        return (
            <React.Fragment>

                <ErrorBoundary>
                    <Suspense fallback={<Spinner/>} maxDuration={1000}>

                        <PublicHeader/>
                        <Switch>
                            <RouteWrapper key="/registration" path="/registration" exact component={Registration} />
                            <RouteWrapper key="/registration/completed" path="/registration/completed" exact component={RegistrationCompleted} />

                            <RouteWrapper key="/registration/mobileTermsAndConditions" path="/registration/mobileTermsAndConditions" exact component={MobileTermsAndConditions} />

                            <RouteWrapper key="/logout" path="/logout" exact component={Logout} />
                            <RouteWrapper key="/request-password-change" path="/request-password-change" exact component={RequestPasswordChange} />
                            <RouteWrapper key="/welcome-to-ami" path="/welcome-to-ami" exact component={RequestPasswordChange} />
                            <RouteWrapper key="/change-password" path="/change-password"  component={ChangePassword}  />
                            <RouteWrapper key="/technical-support" path="/technical-support"  component={TechnicalSupport} />
                            <RouteWrapper key="/request-opt-out" path="/request-opt-out"  component={OptOut} />
                            <RouteWrapper key="/save-for-later" path="/save-for-later"  component={SaveForLaterPublic} />
                            <RouteWrapper key="/oauth/callback" path="/oauth/callback"  component={OauthCallback} />

                            <PublicHomeRouteManager key="/" path="/" exact component={PublicHome} user_id={this.props.user_id} />

                            <Redirect to="/"/>

                        </Switch>

                        <Footer/>

                    </Suspense>
                </ErrorBoundary>

            </React.Fragment>
        );
    }
}



const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id
    }
};

export default connect(mapStateToProps, null)(withRouter(PublicRoutes));
