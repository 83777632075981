import React, { Component } from 'react';
import {Switch, Redirect} from 'react-router-dom';
import {withRouter} from 'react-router-dom';
import {Suspense} from 'react';

import {connect} from 'react-redux';

import {RoleTypes} from './models/RoleTypes';

import ProtectedRoute from './hoc/RouteWrapper/ProtectedRoute';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

import ErrorBoundary from './hoc/ErrorBoundary/ErrorBoundary';
import Spinner from './components/UI/Spinner/Spinner';


// Lazy Load...

const PageNotAvailable = React.lazy(() => import('./pages/private/site-status/PageNotAvailable'));
const NotAuthorized = React.lazy(() => import('./pages/private/site-status/NotAuthorized'));

const TechnicalSupport = React.lazy(() => import('./pages/public/TechnicalSupport/TechnicalSupport'));
const PrivateHome = React.lazy(() => import('./pages/private/Home/PrivateHome'));

const Videos = React.lazy(() => import('./pages/private/content/videos/Videos/Videos'));
const Podcasts = React.lazy(() => import('./pages/private/content/podcasts/Podcasts/Podcasts'));

const WhatWeDo = React.lazy(() => import('./pages/private/about-us/WhatWeDo/WhatWeDo'));

const RequestProgram = React.lazy(() => import('./pages/private/events/RequestProgram/RequestProgram'));
const RequestProgramCompleted = React.lazy(() => import('./pages/private/events/RequestProgram/RequestProgramCompleted'));

const Resources = React.lazy(() => import('./pages/private/resources/Resources'));
const ProductInfo = React.lazy(() => import('./pages/private/resources/ProductInfo/ProductInfo'));
const KeysInfo = React.lazy(() => import('./pages/private/resources/Keys/Keys'));
const SocialMedia = React.lazy(() => import('./pages/private/resources/SocialMedia/SocialMedia'));
const Programs = React.lazy(() => import('./pages/private/resources/Programs/Programs'));

const ProductCodingAndReimbursement = React.lazy(() => import('./pages/private/resources/ProductCodingAndReimbursement/ProductCodingAndReimbursement'));

const ProductContactUs1Form = React.lazy(() => import('./pages/private/resources/ProductCodingAndReimbursement/ProductContactUs1Form'));
const ProductContactUs1Submitted = React.lazy(() => import('./pages/private/resources/ProductCodingAndReimbursement/ProductContactUs1Submitted'));

const SaveForLater = React.lazy(() => import('./pages/private/content/SaveForLater/SaveForLater'));

const LiveEventsScreen = React.lazy(() => import('./pages/private/events/LiveEventsScreen/LiveEventsScreen'));
const EventDetails = React.lazy(() => import('./pages/private/events/EventDetails/EventDetails'));

const Profile = React.lazy(() => import('./pages/private/account/Profile/Profile'));

const VideoDetails = React.lazy(() => import('./pages/private/content/videos/VideoDetails/VideoDetails'));

const RateThisContentCompleted = React.lazy(() => import('./pages/private/content/RateThisContent/RateThisContentCompleted'));

const ContentByCategorySearchPage = React.lazy(() => import('./pages/private/content/ContentByCategorySearch/ContentByCategorySearchPage'));


const GeneralInquiry = React.lazy(() => import('./pages/private/support/GeneralInquiry/GeneralInquiry'));
const GeneralInquiryCompleted = React.lazy(() => import('./pages/private/support/GeneralInquiry/GeneralInquiryCompleted'));

const ContactMyRep = React.lazy(() => import('./pages/private/support/ContactMyRep/ContactMyRep'));
const ContactMyRepCompleted = React.lazy(() => import('./pages/private/support/ContactMyRep/ContactMyRepCompleted'));


// Sales Team
const CustomerSearch = React.lazy(() => import('./pages/private/sales-team/customers/CustomerSearch/CustomerSearch'));
const CustomerActivity = React.lazy(() => import('./pages/private/sales-team/customers/CustomerActivity/CustomerActivity'));
const CustomerReport = React.lazy(() => import('./pages/private/sales-team/customers/CustomerReport/CustomerReport'));

const EmailHome = React.lazy(() => import('./pages/private/sales-team/email/EmailHome/EmailHome'));
const SelectTemplate = React.lazy(() => import('./pages/private/sales-team/email/SelectTemplate/SelectTemplate'));
const SelectRecipients = React.lazy(() => import('./pages/private/sales-team/email/SelectRecipients/SelectRecipients'));
const EmailMessage = React.lazy(() => import('./pages/private/sales-team/email/EmailMessage/EmailMessage'));
const SelectContent = React.lazy(() => import('./pages/private/sales-team/email/SelectContent/SelectContent'));
const SelectEvents = React.lazy(() => import('./pages/private/sales-team/email/SelectEvents/SelectEvents'));
const ReviewEmail = React.lazy(() => import('./pages/private/sales-team/email/ReviewEmail/ReviewEmail'));
const PreviewEmail = React.lazy(() => import('./pages/private/sales-team/email/PreviewEmail/PreviewEmail'));
const ViewSentEmailDetails = React.lazy(() => import('./pages/private/sales-team/email/ViewSentEmailDetails/ViewSentEmailDetails'));


// Home Office/Admin
// const ManageUsersSearchPage = React.lazy(() => import('./pages/private/manage/manage-users/UserSearch/UserSearchPage'));
const ManageUsersSearch = React.lazy(() => import('./pages/private/manage/manage-users/user-search/ManageUserSearch'));
const ManageUser = React.lazy(() => import('./pages/private/manage/manage-users/ManageUser/ManageUser'));

const ManageContentSearchPage = React.lazy(() => import('./pages/private/manage/manage-content/ContentSearch/ContentSearchPage'));
const AddContentPage = React.lazy(() => import('./pages/private/manage/manage-content/ManageContent/AddContentPage'));
const ManageContentPage = React.lazy(() => import('./pages/private/manage/manage-content/ManageContent/ManageContentPage'));

const MediaAnalyticsOverview = React.lazy(() => import('./pages/private/analytics/media/MediaAnalyticsOverview/MediaAnalyticsOverview'));

const MediaAnalyticsOverviewReport = React.lazy(() => import('./pages/private/analytics/media/media-analytics-report/overview/MediaAnalyticsOverviewReport'));

const MediaRatingsOverviewReport = React.lazy(() => import('./pages/private/analytics/media/media-ratings-report/overview/MediaRatingsOverviewReport'));
const MediaRatingsDetailsReport = React.lazy(() => import('./pages/private/analytics/media/media-ratings-report/details/MediaRatingsDetailsReport'));

const MediaFacultyOverviewReport = React.lazy(() => import('./pages/private/analytics/media/media-faculty-report/overview/MediaFacultyOverviewReport'));
const MediaFacultyDetailsReport = React.lazy(() => import('./pages/private/analytics/media/media-faculty-report/details/MediaFacultyDetailsReport'));

const MediaUserOverviewReport = React.lazy(() => import('./pages/private/analytics/media/media-user-report/overview/MediaUserOverviewReport'));


const TerritoryMetricsReport = React.lazy(() => import('./pages/private/analytics/sales-team/territory-metrics/TerritoryMetricsReport'));
const NotifyMeReport = React.lazy(() => import('./pages/private/analytics/customer-requests/NotifyMeReport/NotifyMeReport'));

const EventsOverviewReport = React.lazy(() => import('./pages/private/analytics/events/events-report/overview/EventsOverviewReport'));
const EventDetailsReport = React.lazy(() => import('./pages/private/analytics/events/events-report/details/EventDetailsReport'));

const OptOutOverviewReport = React.lazy(() => import('./pages/private/analytics/users/opt-out-report/overview/OptOutOverviewReport'));

const SalesEmailOverviewReport = React.lazy(() => import('./pages/private/analytics/emails/sales-team-reports/overview/SalesEmailOverviewReport'));
const SalesEmailDetailsReport = React.lazy(() => import('./pages/private/analytics/emails/sales-team-reports/details/SalesEmailDetailsReport'));


const EvaluationList = React.lazy(() => import('./pages/private/evaluations/list/EvaluationList'));
const ManageEvaluation = React.lazy(() => import('./pages/private/evaluations/manage/ManageEvaluation'));
const ShareEvaluation = React.lazy(() => import('./pages/private/evaluations/share/ShareEvaluation'));

const ReportingDashboard =              React.lazy(() => import('./pages/private/analytics/dashboard/ReportingDashboard'));

const CustomerReportsSubpage =          React.lazy(() => import('./pages/private/analytics/dashboard/customer/CustomerReportsSubpage'));
const NewCustomerAOI =                  React.lazy(() => import('./pages/private/analytics/dashboard/customer/NewCustomerAOI'));
const CustomersBySpecialty =            React.lazy(() => import('./pages/private/analytics/dashboard/customer/CustomersBySpecialty'));
const KeysReports =                      React.lazy(() => import('./pages/private/analytics/dashboard/customer/KeysReports'));

const MediaReportsSubpage =             React.lazy(() => import('./pages/private/analytics/dashboard/media/MediaReportsSubpage'));
const TopMediaByApplication =           React.lazy(() => import('./pages/private/analytics/dashboard/media/TopMediaByApplication'))
const ContentReviewsSummaryReportPage = React.lazy(() => import('./pages/private/analytics/dashboard/media/ContentReviewsSummaryReportPage'));

const SalesRepReportsSubpage =          React.lazy(() => import('./pages/private/analytics/dashboard/sales-rep/SalesRepReportsSubpage'));
const Top20SalesReps =                  React.lazy(() => import('./pages/private/analytics/dashboard/sales-rep/Top20SalesReps'));
const SalesEmailReport =                React.lazy(() => import('./pages/private/analytics/dashboard/sales-rep/SalesEmailReport'));

// Eval-V2
const EvaluationEventSelectionScreenV2 = React.lazy(() => import('./pages/private/evaluations-v2/event-selection/EvaluationEventSelectionScreen'));
const EvaluationListV2 = React.lazy(() => import('./pages/private/evaluations-v2/list/EvaluationList'));
const ManageEvaluationV2 = React.lazy(() => import('./pages/private/evaluations-v2/manage/eval/ManageEvaluation'));
const ShareEvaluationV2 = React.lazy(() => import('./pages/private/evaluations-v2/share/ShareEvaluation'));
const ManageQuestions = React.lazy(() => import('./pages/private/evaluations-v2/manage/question-manager/ManageQuestions'));
const PreviewSurvey = React.lazy(() => import('./pages/private/evaluations-v2/manage/preview/PreviewSurvey'));

const EvaluationLanding = React.lazy( () => import('./pages/private/evaluations-v2/landing/EvaluationsLandingPage'));
const EvaluationReporting = React.lazy(() => import('./pages/private/evaluations-v2/reporting/EvaluationReporting'));
const EvaluationReportDisplay = React.lazy(() => import('./pages/private/evaluations-v2/reporting/EvaluationReportDisplay'));

// Admin
const TaskManagerScreen  = React.lazy(() => import('./pages/private/admin/system-admin/TaskManagerScreen'));






class PrivateRoutes extends Component {


    render() {

       // console.log("PRIVATE ROUTES CALLED", this.props.user_id, this.props.role_id);

        return (
            <React.Fragment>

                <ErrorBoundary>
                    <Suspense fallback={<Spinner/>} maxDuration={1000}>

                        <Header/>

                        <Switch>

                            <ProtectedRoute key="/home" path="/home" exact component={PrivateHome} />

                            <ProtectedRoute key="/home/site-status/page-not-available" path="/home/site-status/page-not-available" exact component={PageNotAvailable} />
                            <ProtectedRoute key="/home/site-status/not-authorized" path="/home/site-status/not-authorized" exact component={NotAuthorized} />

                            <ProtectedRoute key="/home/content/videos" path="/home/content/videos" exact component={Videos} />
                            <ProtectedRoute key="/home/content/video/video-details" path="/home/content/video/video-details/:content_id" exact component={VideoDetails} />

                            <ProtectedRoute key="/home/content/podcasts" path="/home/content/podcasts" exact component={Podcasts} />
                            <ProtectedRoute key="/home/content/my-list" path="/home/content/my-list" exact component={SaveForLater} />

                            <ProtectedRoute key="/home/content/rating/submitted" path="/home/content/rating/submitted" exact component={RateThisContentCompleted} />

                            <ProtectedRoute key="/home/content/by-category" path="/home/content/by-category/:category_id" exact component={ContentByCategorySearchPage} />

                            <ProtectedRoute key="/home/events/live-programs" path="/home/events/live-programs" exact component={LiveEventsScreen} />
                            <ProtectedRoute key="/home/events/event-details" path="/home/events/event-details/:event_id" exact component={EventDetails} />

                            <ProtectedRoute key="/home/events/request-program" path="/home/events/request-program" exact component={RequestProgram} />
                            <ProtectedRoute key="/home/events/request-program-submitted" path="/home/events/request-program-submitted" exact component={RequestProgramCompleted} />

                            <ProtectedRoute key="/home/resources" path="/home/resources" exact component={Resources} />
                            <ProtectedRoute key="/home/resources/product-info" path="/home/resources/product-info" exact component={ProductInfo} />
                            <ProtectedRoute key="/home/resources/keys-info" path="/home/resources/keys-info" exact component={KeysInfo} />
                            <ProtectedRoute key="/home/resources/programs" path="/home/resources/programs" exact component={Programs} />
                            <ProtectedRoute key="/home/resources/social-media" path="/home/resources/social-media" exact component={SocialMedia} />

                            <ProtectedRoute key="/home/resources/product-coding-and-reimbursement" path="/home/resources/product-coding-and-reimbursement" exact component={ProductCodingAndReimbursement} />

                            <ProtectedRoute key="/home/resources/product-contact-us-1-form" path="/home/resources/product-contact-us-1-form" exact component={ProductContactUs1Form} />
                            <ProtectedRoute key="/home/resources/product-contact-us-1-submitted" path="/home/resources/product-contact-us-1-submitted" exact component={ProductContactUs1Submitted} />


                            <ProtectedRoute key="/home/about-us/what-we-do" path="/home/about-us/what-we-do" exact component={WhatWeDo} />

                            <ProtectedRoute key="/home/profile" path="/home/profile" exact component={Profile} />

                            <ProtectedRoute key="/home/support/technical-support" path="/home/support/technical-support"  component={TechnicalSupport} />

                            <ProtectedRoute key="/home/support/general-inquiry" path="/home/support/general-inquiry"  component={GeneralInquiry} />
                            <ProtectedRoute key="/home/support/general-inquiry-submitted" path="/home/support/general-inquiry-submitted"  component={GeneralInquiryCompleted} />

                            <ProtectedRoute key="/home/support/contact-my-rep" path="/home/support/contact-my-rep"  component={ContactMyRep} />
                            <ProtectedRoute key="/home/support/contact-my-rep-submitted" path="/home/support/contact-my-rep-submitted"  component={ContactMyRepCompleted} />

                            <ProtectedRoute key="/home/sales-team/customers/list" path="/home/sales-team/customers/search"  component={CustomerSearch}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/customers/activity" path="/home/sales-team/customers/activity/:customer_id"  component={CustomerActivity}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/analytics/events/events-report/overview" path="/home/analytics/events/events-report/overview"  component={EventsOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/analytics/events/events-report/details" path="/home/analytics/events/events-report/details"  component={EventDetailsReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/sales-team/email/home" path="/home/sales-team/email/home"  component={EmailHome}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/select-template" path="/home/sales-team/email/select-template"  component={SelectTemplate}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/select-recipients" path="/home/sales-team/email/select-recipients"  component={SelectRecipients}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/email-message" path="/home/sales-team/email/email-message"  component={EmailMessage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/select-content" path="/home/sales-team/email/select-content"  component={SelectContent}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/select-events" path="/home/sales-team/email/select-events"  component={SelectEvents}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/review-email" path="/home/sales-team/email/review-email"  component={ReviewEmail}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/preview-email" path="/home/sales-team/email/preview-email"  component={PreviewEmail}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/sales-team/email/sent/view" path="/home/sales-team/email/sent/view"  component={ViewSentEmailDetails}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/manage/manage-users/search" path="/home/manage/manage-users/search" exact component={ManageUsersSearch}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/manage/manage-users/manage-user" path="/home/manage/manage-users/manage-user/:user_id" exact component={ManageUser}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/manage/manage-content/search" path="/home/manage/manage-content/search" exact component={ManageContentSearchPage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/manage/manage-content/add-content" path="/home/manage/manage-content/add-content" exact component={AddContentPage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/manage/manage-content/update-content" path="/home/manage/manage-content/update-content/:contentId" exact component={ManageContentPage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations/list" path="/home/evaluations/list" exact component={EvaluationList}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations/manage" path="/home/evaluations/manage" exact component={ManageEvaluation}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations/share" path="/home/evaluations/share" exact component={ShareEvaluation}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>


                            <ProtectedRoute key="/home/evaluations-v2/event-selection" path="/home/evaluations-v2/event-selection" exact component={EvaluationEventSelectionScreenV2}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/list" path="/home/evaluations-v2/list" exact component={EvaluationListV2}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/manage" path="/home/evaluations-v2/manage/survey" exact component={ManageEvaluationV2}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/manage/questions" path="/home/evaluations-v2/manage/questions" exact component={ManageQuestions}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/manage/preview" path="/home/evaluations-v2/manage/preview" exact component={PreviewSurvey}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>


                            <ProtectedRoute key="/home/evaluations-v2/share" path="/home/evaluations-v2/share" exact component={ShareEvaluationV2}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/landing" path="/home/evaluations-v2/landing" exact component={EvaluationLanding}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/reporting" path="/home/evaluations-v2/reporting" exact component={EvaluationReporting}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/evaluations-v2/report-display" path="/home/evaluations-v2/report-display" exact component={EvaluationReportDisplay}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/admin/system-admin/task-manager" path="/home/admin/system-admin/task-manager" exact component={TaskManagerScreen}
                                            allowed_roles={[RoleTypes.ADMIN ]}/>



                            <ProtectedRoute key="/home/reporting-dashboard" path="/home/reporting-dashboard" exact component={ReportingDashboard}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports" path="/home/reporting-dashboard/customer-reports" exact component={CustomerReportsSubpage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports/registrations-AOI" path="/home/reporting-dashboard/customer-reports/registrations-AOI" exact component={NewCustomerAOI}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports/registrations-type" path="/home/reporting-dashboard/customer-reports/registrations-type" exact component={CustomersBySpecialty}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports/keys-reports" path="/home/reporting-dashboard/customer-reports/keys-reports" exact component={KeysReports}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports/customer-reporting" path="/home/reporting-dashboard/customer-reports/customer-reporting"  component={CustomerReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports/notify-user-report" path="/home/reporting-dashboard/customer-reports/notify-user-report"  component={NotifyMeReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/customer-reports/opt-out-report" path="/home/reporting-dashboard/customer-reports/opt-out-report"  component={OptOutOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />


                            <ProtectedRoute key="/home/reporting-dashboard/media-reports" path="/home/reporting-dashboard/media-reports" exact component={MediaReportsSubpage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/top-media-by-application" path="/home/reporting-dashboard/media-reports/top-media-by-application" exact component={TopMediaByApplication}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/content-reviews-summary" path="/home/reporting-dashboard/media-reports/content-reviews-summary" exact component={ContentReviewsSummaryReportPage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-user-report" path="/home/reporting-dashboard/media-reports/media-user-report"  component={MediaUserOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-overview" path="/home/reporting-dashboard/media-reports/media-overview"  component={MediaAnalyticsOverview}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-analytics-report" path="/home/reporting-dashboard/media-reports/media-analytics-report"  component={MediaAnalyticsOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-faculty-report" path="/home/reporting-dashboard/media-reports/media-faculty-report"  component={MediaFacultyOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-faculty-report-details" path="/home/reporting-dashboard/media-reports/media-faculty-report-details"  component={MediaFacultyDetailsReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-rating-report" path="/home/reporting-dashboard/media-reports/media-rating-report"  component={MediaRatingsOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/media-reports/media-rating-report-details" path="/home/reporting-dashboard/media-reports/media-rating-report-details"  component={MediaRatingsDetailsReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />


                            <ProtectedRoute key="/home/reporting-dashboard/sales-rep-reports" path="/home/reporting-dashboard/sales-rep-reports" exact component={SalesRepReportsSubpage}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/sales-rep-reports/top-sales-reps" path="/home/reporting-dashboard/sales-rep-reports/top-sales-reps" exact component={Top20SalesReps}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/sales-rep-reports/sales-email" path="/home/reporting-dashboard/sales-rep-reports/sales-email" exact component={SalesEmailReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]}/>

                            <ProtectedRoute key="/home/reporting-dashboard/sales-rep-reports/sales-email-overview" path="/home/reporting-dashboard/sales-rep-reports/sales-email-overview"  component={SalesEmailOverviewReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/sales-rep-reports/sales-email-details" path="/home/reporting-dashboard/sales-rep-reports/sales-email-details"  component={SalesEmailDetailsReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED, RoleTypes.SALES_TEAM ]} />

                            <ProtectedRoute key="/home/reporting-dashboard/sales-rep-reports/territory-metrics" path="/home/reporting-dashboard/sales-rep-reports/territory-metrics"  component={TerritoryMetricsReport}
                                            allowed_roles={[RoleTypes.ADMIN, RoleTypes.MED_ED ]} />


                            <Redirect to="/home"/>

                        </Switch>

                        <Footer/>

                    </Suspense>
                </ErrorBoundary>

            </React.Fragment>
        );
    }
}




const mapStateToProps = (state) => {
    return {
        user_id: state.security.user_id,
        role_id: state.security.role_id
    }
};


export default connect(mapStateToProps, null)(withRouter(PrivateRoutes));
