export const RoleTypes = {
    NONE:0,
    ADMIN:1,
    MED_ED:10,
    SALES_TEAM:50,
    HOME_OFFICE_NON_SALES:70,
    HCP:100,
    KEYS:150
};



export const isKEYS_RoleType = (role_id) => {

    return role_id===RoleTypes.KEYS;
};





