import React from 'react';

const MobileHamburger = (props) => {

    let img;
    if(props.open){
        img = require("../../assets/images/icons/ico-mobile-hamburger.svg").default;
    } else {
        img = require("../../assets/images/icons/ico-mobile-hamburger-close.svg").default;
    }

    return (
        <img src={img} alt=""/>
    );
};

export default MobileHamburger;