import React from 'react';




/**
 * CHECKED-2021
 *
 * Returns a formatted string of the Error object.
 */
export const buildLogMessage = (error) => {

    if(!error){
        return "[error is not defined]";
    }

    if(error.response){
        //
        // Try the "response object first.
        // This would be from the axios calls to the system services.
        //
        let message = "[http="+error.response.status+"]";

        if( error.response.data ){
            // console.log(error.response.data);
            message = message+"[status="+error.response.data.status+"][message="+error.response.data.message+"]";
        }
        return message;
    } else if(error.message){
        //
        // Try general "message" field.
        //
        return "[message="+error.message+"]";
    }

    //
    // All else, return error object.
    //
    return error;
};




/**
 * CHECKED-2021
 *
 * Returns the error message from an Error object.
 */
export const getErrorDataMessage = (error, defaultMessage) => {

    //
    // Check service based errors first.
    //
    if(error && error.response && error.response.data && error.response.data.message){
        return error.response.data.message;
    }

    //
    // General message of an error object.
    //
    if(error && error.message){
        return error.message;
    }

    return defaultMessage;
};




export const getServiceErrorMessageOrUseDefault = (error, defaultMessage) => {

    if( error && error.response && error.response.data && error.response.data.message ){
        return error.response.data.message;
    }

    return defaultMessage;
};


/**
 * CHECKED-2021
 *
 * Returns the status from a service call.
 */
export const getServiceErrorStatus = (error) => {

    if( error && error.response && error.response.data && error.response.data.status ){
        return error.response.data.status;
    }

    return null;
};





/**
 * CHECKED-2021
 *
 * Use in local service calls to log user out if the services indicate they are not authenticated.
 */
export const checkForAuthorizationError = (error, history) => {

    try {
        if (error.response && error.response.data && error.response.status === 401) {

            if (history) {
                history.push("/logout");
            }
        }
    }catch(err){
        console.log("Error thrown in checkForAuthorizationError()", err);
    }
};



/**
 * CHECKED-2021
 *
 * Use in local service calls to send user to the not authorized screen.
 */
export const checkForAuthorizationErrorSendToNotAuthorizedScreen = (error, history) => {

    try {
        if (error.response && error.response.data && error.response.status === 401) {

            if (history) {
                history.push("/home/site-status/not-authorized");
            }
        }
    }catch(err){
        console.log("Error thrown in checkForAuthorizationErrorSendToNotAuthorizedScreen()", err);
    }
};



export const DEFAULT_ERROR_MESSAGE_1 = (<span>An error has occurred.<br/>Please try again.<br/>If the problem persists, please contact support.</span>);

export const DEFAULT_ERROR_MESSAGE_2 = (<span>An error has occurred.</span>);

export const buildDefaultErrorMessageWithMessage = (message) => {
    return (

        <span>An error has occurred.
            <br/>
            Please try again.
            <br/>
            If the problem persists, please contact support.
            <br/>
            <br/>
            Reason: {message}
        </span>
    )
};





// export default {
//     buildLogMessage,
//     getErrorDataMessage,
//     checkForAuthorizationError,
//     buildDefaultErrorMessageWithMessage,
//     getServiceErrorMessageOrUseDefault,
//     getServiceErrorStatus,
//     DEFAULT_ERROR_MESSAGE_1
// }